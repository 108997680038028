import React, { useState } from "react";
import styled from "styled-components";
import { Button, Dropdown, Input, ModalCard, Tag, Tooltip } from "@darktrace/ui-components";
import { assignTag, deleteTag, useCreateTag, usePTNAlertTags, useUserOwnRoles } from "../../../../logic/api";
import { useActiveClientId } from "../../../../logic/hooks";

const StyledModalCard = styled(ModalCard)`
  .dt-ui-modal-card {
    display: flex;
    flex-direction: column;
    width: 40vw;
    max-width: 35rem;

    .dt-ui-card__contents {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1.6rem;
      max-height: 30vh;

      .top {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        .grid {
          display: grid;
          align-items: center;
          column-gap: 1rem;
          row-gap: 0.4rem;
          grid-template-columns: 1fr auto;
        }
        .new-tag {
          padding: 0.8rem 0;
          span {
            display: flex;
            gap: 0.8rem;
            align-items: center;
            .dt-ui-input__wrapper {
              flex-grow: 1;
            }
          }
          p {
            text-transform: uppercase;
            font-weight: 600;
            color: var(--dt-ui-card-title-text-color);
            font-size: 1rem;
            padding-bottom: 0.4rem;
          }
        }
        .selected-tags {
          display: flex;
          flex-wrap: wrap;
          gap: 0.8rem;
        }
      }

      .action-buttons {
        display: flex;
        gap: 0.8rem;
        button {
          flex-grow: 1;
        }
      }
    }
  }
`;

export function TagsModal({ onClose = () => {}, open, existingTags = [], alertId, ...attrs }) {
  const activeClientId = useActiveClientId();

  const { mutate: createTag, isPending: isCreateTagPending } = useCreateTag({ clientId: activeClientId });
  const { data: userOwnRoles = [] } = useUserOwnRoles({ clientId: activeClientId });
  const isInternalSOCManager = userOwnRoles.find((role) => role.id === "internal-client-role-cloud-soc-manager");

  const modalRoot = document.querySelector("#modal-root");

  const [selectedTags, setSelectedTags] = useState(existingTags);
  const { data: availableTags } = usePTNAlertTags(activeClientId);
  const formattedDropdownItems = availableTags?.map((item) => ({
    ...item,
    selected: selectedTags.some((t) => t.id === item.id),
  }));

  const [showAddNewTag, setShowAddNewTag] = useState(false);
  const [newTagName, setNewTagName] = useState("");
  const [newTagDesc, setNewTagDesc] = useState("");

  const placeholder = t(`Select Tags`);
  const isSaveDisabled =
    !selectedTags.length ||
    (selectedTags.length === existingTags.length && selectedTags.every((x) => existingTags.find((y) => y.id === x.id))) ||
    isCreateTagPending;

  function handleClose() {
    setShowAddNewTag(false);
    onClose();
  }

  function handleSave() {
    if (!selectedTags.length) return;
    const tagsToAssign = selectedTags.filter((tag) => !existingTags.find((existing) => existing.id === tag.id));
    const tagsToDelete = existingTags.filter((tag) => !selectedTags.find((existing) => existing.id === tag.id));
    tagsToAssign.forEach((tag) => {
      assignTag({ alertId, clientId: activeClientId, tag });
    });
    tagsToDelete.forEach((tag) => {
      deleteTag({ alertId, clientId: activeClientId, tag });
    });
    handleClose();
  }

  function handleCreateNewTag() {
    if (!newTagName) return;
    createTag({
      clientId: activeClientId,
      tag: { label: newTagName, description: newTagDesc },
      onSuccess: ({ id }) => {
        const newTag = { id, label: newTagName, description: newTagDesc };
        setSelectedTags((prev) => [...prev, newTag]);
        setNewTagName("");
        setNewTagDesc("");
      },
    });
  }

  return (
    <StyledModalCard modalRoot={modalRoot} open={open} title={t(`Tags`)} {...attrs}>
      <div className="top">
        <div className="grid">
          <Dropdown
            hasSearch
            displayValue={selectedTags.length ? t(`{0} Tags Selected`, selectedTags.length) : placeholder}
            placeholder={placeholder}
            items={formattedDropdownItems}
            onSelect={(id) => {
              if (selectedTags.some((tag) => tag.id === id)) return;
              setSelectedTags((prev) => [...prev, formattedDropdownItems.find((x) => x.id === id)]);
            }}
          />
          {isInternalSOCManager ? (
            <Button variant="tertiary" icon="plus far" onClick={() => setShowAddNewTag(true)}>
              {t(`Create New Tag`)}
            </Button>
          ) : null}
        </div>
        {showAddNewTag && isInternalSOCManager ? (
          <div className="new-tag">
            <p>{t(`Create new tag`)}</p>
            <span>
              <span>
                <Input placeholder={t(`Name`)} value={newTagName} onChange={(val) => setNewTagName(val)} />
                <Input placeholder={t(`Description`)} value={newTagDesc} onChange={(val) => setNewTagDesc(val)} />
              </span>
              <Button variant="tertiary" disabled={!(newTagName && newTagDesc)} onClick={handleCreateNewTag}>
                {t(`Confirm`)}
              </Button>
            </span>
          </div>
        ) : null}
        <div className="selected-tags">
          {selectedTags.map(({ display_name, label, id, description }) => (
            <Tooltip key={id} text={description}>
              <Tag
                text={display_name || label}
                actionIcon="times"
                onActionClick={() => setSelectedTags((prev) => prev.filter((tag) => tag.id !== id))}
              />
            </Tooltip>
          ))}
        </div>
      </div>

      <div className="action-buttons">
        <Button variant="secondary" onClick={handleClose}>
          {t(`Cancel`)}
        </Button>
        <Button disabled={isSaveDisabled} onClick={handleSave}>
          {t(`Save`)}
        </Button>
      </div>
    </StyledModalCard>
  );
}
