import { Button, IconButton, Input, ModalCard, Table, TableWrapper, Tooltip } from "@darktrace/ui-components";
import React, { useState } from "react";
import styled from "styled-components";
import { ClientSelectorSubheader } from "./user-management/ClientSelectorSubheader.jsx";
import { useSelector } from "react-redux";
import { useActiveClientId } from "../logic/hooks.js";
import {
  useClientDomains,
  useClientPendingDomainsQuery,
  useDeletePendingDomainMutation,
  useRequestDomainUsageMutation,
  useVerifyPendingDomainMutation,
} from "../logic/api.js";

const StyledManageCustomDomainsModal = styled(ModalCard)`
  .dt-ui-modal-card {
    overflow: auto;

    .dt-ui-card__contents {
      padding: 0;
      display: flex;
      flex-direction: column;

      .content {
        display: flex;
        flex-direction: column;
        padding-top: 0.8rem;
        overflow-y: auto;
        max-height: 100%;

        .request-new-domain {
          padding-inline: 0.8rem;
          display: grid;
          grid-template-columns: 1fr auto;
          gap: 0.8rem;
          padding-bottom: 0.8rem;

          .dt-ui-input__wrapper {
            width: 100%;
          }
        }

        .status {
          display: flex;
          align-items: center;
          gap: 0.4rem;

          .status-text {
            font-weight: 600;

            &.pending {
              color: var(--dt-ui-medium);
            }
            &.claimed {
              color: var(--dt-ui-resolved);
            }
          }
        }

        .actions {
          display: flex;
          gap: 0.4rem;
          align-items: center;
        }

        .actions-column {
          width: 0;
        }
      }
    }
  }
`;

const StyledTooltipText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  .txt-record-prefix {
    font-weight: 700;
  }
`;

export function ManageCustomDomainsModal({ open, onClose }) {
  const modalRoot = document.querySelector("#modal-root");
  const activeClientId = useActiveClientId();
  const [selectedClientId, setSelectedClientId] = useState(activeClientId);

  const [domain, setDomain] = useState();

  const _clients = useSelector((state) => state.app.clients);
  const clients = _clients.map((client) => ({ ...client, selected: client.id === selectedClientId }));

  const { data: clientPendingDomains = {} } = useClientPendingDomainsQuery({ clientId: selectedClientId });
  const { pendingDomains = [], txtRecordPrefix } = clientPendingDomains;

  const { data: clientDomains = [] } = useClientDomains({ clientId: selectedClientId });

  const allDomains = [
    ...clientDomains
      .filter((domain) => !pendingDomains.some(({ domain: d }) => domain === d))
      .map((domain) => ({ domain, isDefault: true })),
    ...pendingDomains,
  ];
  const showActionsColumn = allDomains.some(({ claimed, isDefault }) => !claimed && !isDefault);

  const { mutateAsync: requestDomainUsage } = useRequestDomainUsageMutation({ clientId: selectedClientId, domain });
  const { mutateAsync: deletePendingDomain } = useDeletePendingDomainMutation({ clientId: selectedClientId });
  const { mutateAsync: verifyPendingDomain } = useVerifyPendingDomainMutation({ clientId: selectedClientId });

  const domainsTableData = allDomains.map(({ id, isDefault, domain, claimed }) => [
    // DOMAIN
    domain,

    // STATUS
    <div className="status" key="status">
      <span className={`status-text${isDefault ? "" : claimed ? " claimed" : " pending"}`}>
        {isDefault ? t(`Default`) : claimed ? t(`Claimed`) : t(`Pending`)}
      </span>
      {!claimed && !isDefault && (
        <Tooltip
          text={
            <StyledTooltipText>
              <span>
                In order to claim this domain, add the following TXT Record Prefix to this domain in your domain management software.
              </span>
              <span className="txt-record-prefix">
                {txtRecordPrefix}
                {id}
              </span>
            </StyledTooltipText>
          }
        />
      )}
    </div>,

    // ACTIONS
    ...(showActionsColumn
      ? [
          <div key="actions" className="actions">
            {id && !claimed && (
              <>
                <Tooltip text="Verify">
                  <IconButton variant="tertiary" icon="refresh" onClick={() => verifyPendingDomain({ domainId: id, domain })} />
                </Tooltip>
                <Tooltip text="Remove">
                  <IconButton variant="tertiary" icon="trash" onClick={() => deletePendingDomain({ id, domain })} />
                </Tooltip>
              </>
            )}
          </div>,
        ]
      : []),
  ]);

  return (
    <StyledManageCustomDomainsModal
      open={open}
      onClose={onClose}
      modalRoot={modalRoot}
      title={t(`Manage Custom Domains`)}
      subheaders={[
        {
          size: "large",
          component: <ClientSelectorSubheader clients={clients} onSelectClient={setSelectedClientId} />,
        },
      ]}
    >
      <div className="content">
        <div className="request-new-domain">
          <Input value={domain} onChange={setDomain} placeholder={t(`Enter domain to request usage`)} />
          <Button onClick={() => requestDomainUsage().then(() => setDomain(""))}>Request Domain Usage</Button>
        </div>

        {allDomains.length > 0 && (
          <TableWrapper>
            <Table
              headers={[
                { id: "domain", title: t(`Domain`) },
                { id: "status", title: t(`Status`) },
                ...(showActionsColumn ? [{ id: "actions", title: null, className: "actions-column" }] : []),
              ]}
              data={domainsTableData}
            />
          </TableWrapper>
        )}
      </div>
    </StyledManageCustomDomainsModal>
  );
}
