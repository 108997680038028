import { ButtonGroup } from "@darktrace/ui-components";
import dayjs from "dayjs";
import React from "react";
import styled from "styled-components";

const StyledHighlight = styled.div`
  span {
    color: var(--dt-ui-polar-glacier-300);
  }
`;

export function ShiftButtonGroup({ handover, active, setActive = () => {} }) {
  function getHandover(unit) {
    return Math.abs(dayjs().diff(handover, unit));
  }
  return (
    <ButtonGroup
      size="small"
      activeButtonId={active}
      onClick={(id) => setActive(id)}
      buttons={[
        { id: "current", text: t(`Online Now`) },
        {
          id: "next",
          text: (
            <StyledHighlight>
              {t(`Next`)}{" "}
              <span>{`${getHandover("hour")}h ${getHandover("minute") % 60}min${getHandover("minute") % 60 === 1 ? "" : "s"}`}</span>
            </StyledHighlight>
          ),
        },
      ]}
    />
  );
}
