import { Tile } from "@darktrace/ui-components";
import React from "react";
import styled from "styled-components";

const StyledTile = styled(Tile)`
  height: 3.6rem;
  i {
    padding-right: 1.2rem;
    color: var(--dt-ui-button-tertiary-hover-text-color);
  }
  .dt-ui-tile__body-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export function AnalystTile({ name, onClick = () => {}, active = false, disabled = false, right = null }) {
  return (
    <StyledTile noColorBar onClick={onClick} active={active} disabled={disabled}>
      <span>
        <i className="far fa-user"></i>
        {name}
      </span>
      <span>{right}</span>
    </StyledTile>
  );
}
