import { configureStore, createSlice } from "@reduxjs/toolkit";
import { defaultAlertsTabFilters } from "../components/soc-dashboard/utils";

const initialState = {
  isUserVerified: undefined,
  isReverifyingUser: false,
  defaultClientId: undefined,
  clients: [],
  toasts: [],
  toastsWhileReverifying: [],
  showTimeoutOverlay: false,
  isSidepanelCollapsed: localStorage.getItem("sidepanel-collapsed") === "true",
  SOCFilters: defaultAlertsTabFilters,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsUserVerified: (state, action) => ({ ...state, isUserVerified: action.payload }),
    setIsReverifyingUser: (state, action) => {
      if (action.payload) return { ...state, isReverifyingUser: true };
      else
        return {
          ...state,
          isReverifyingUser: false,
          toasts: [...state.toasts, ...state.toastsWhileReverifying],
          toastsWhileReverifying: [],
        };
    },
    setDefaultClientId: (state, action) => ({ ...state, defaultClientId: action.payload }),
    setClients: (state, action) => ({ ...state, clients: action.payload }),
    newToast: (state, action) => {
      // don't show error toast when already showing timeout overlay
      const toast = { delay: 5000, ...action.payload };
      if (action.payload.variant === "error") {
        if (state.showTimeoutOverlay) return state;
        if (state.isReverifyingUser) return { ...state, toastsWhileReverifying: [...state.toastsWhileReverifying, toast] };
      }
      return { ...state, toasts: [...state.toasts, toast] };
    },
    setShowTimeoutOverlay: (state, action) => ({ ...state, showTimeoutOverlay: action.payload }),
    setIsSidepanelCollapsed: (state, action) => {
      localStorage.setItem("sidepanel-collapsed", action.payload);
      return { ...state, isSidepanelCollapsed: action.payload };
    },
    setSOCFilters: (state, action) => ({ ...state, SOCFilters: action.payload }),
  },
});

// Action creators are generated for each case reducer function
export const {
  setClients,
  setDefaultClientId,
  setIsUserVerified,
  setIsReverifyingUser,
  newToast,
  setShowTimeoutOverlay,
  setIsSidepanelCollapsed,
  setSOCFilters,
} = appSlice.actions;
export const store = configureStore({ reducer: { app: appSlice.reducer } });
