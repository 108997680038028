export const mockComments = [
  {
    id: "9cbfcd9f-e467-4fc0-8cf7-892eecd65a05",
    created_by: "9ca78d63-6be0-4652-97c2-69b79abd6651",
    commentable_type: "alerts",
    commentable_id: "9ca78d63-ca38-4d8a-b41d-76c9a16ccb60",
    content: "Test comment here!",
    created_at: "2024-08-12T15:20:24.000000Z",
    updated_at: "2024-08-12T15:20:24.000000Z",
  },
  {
    id: "9cc78a78-eb15-4477-a531-9ccde2bffa60",
    created_by: "9b9c2510-4361-41c5-8e4e-49ce18b2fd97",
    commentable_type: "alerts",
    commentable_id: "9ca78d63-ca38-4d8a-b41d-76c9a16ccb60",
    content: "Added a comment here",
    created_at: "2024-08-16T11:39:16.000000Z",
    updated_at: "2024-08-16T11:39:16.000000Z",
  },
  {
    id: "9cc78b4f-d6a2-42b3-8455-7abe1127cdb3",
    created_by: "9b9c2510-4361-41c5-8e4e-49ce18b2fd97",
    commentable_type: "alerts",
    commentable_id: "9ca78d63-ca38-4d8a-b41d-76c9a16ccb60",
    content: "Another comment here",
    created_at: "2024-08-16T11:41:36.000000Z",
    updated_at: "2024-08-16T11:41:36.000000Z",
  },
  {
    id: "9cc7acf1-121d-4f81-a163-99da894732b8",
    created_by: "9b9c2510-4361-41c5-8e4e-49ce18b2fd97",
    commentable_type: "alerts",
    commentable_id: "9ca78d63-ca38-4d8a-b41d-76c9a16ccb60",
    content:
      "Testing again Added a comment hereAdded a comment hereAdded a comment hereAdded a comment hereAdded a comme≈nt hereAdded a comment hereAdded a comment hereAdded a comment here",
    created_at: "2024-08-16T13:15:39.000000Z",
    updated_at: "2024-08-16T13:15:39.000000Z",
  },
];

export const mockTags = [
  { id: "9c1c711c-9916-4a38-a2d8-e129cd0a131e", label: "Test Tag", description: "A testy test tag" },
  {
    id: "9c1c711c-a4ed-4f87-892c-7bfc21e865b8",
    label: "Banking Trojan",
    description: "Tag for activity which appears to relate to banking trojan infections",
  },
  {
    id: "9c1c711c-a68f-4c9e-ac8d-1743b95b05d8",
    label: "Confirmed Compromise",
    description:
      "A tag to tag ptns which have uncovered confirmed compromises Note: A tag used by the monthly SOC reports, please do not apply unless certain this is compromise.",
  },
  {
    id: "9c1c711c-a880-4cd6-ad55-73207c3a18ed",
    label: "Insider Threat",
    description: "Tag for scenarios where customer confirms or it is clear that this is an insider attack",
  },
  {
    id: "9c1c711c-aa2a-46a8-80be-350fdd2bec24",
    label: "Account Issues",
    description: "Generic tag to highlight Account Issues - will be used for monthly service reports",
  },
  { id: "9c1c711c-abe6-46f5-89ac-d8de69a6dbf1", label: "SilverSculpture", description: "Tag to track the SilverSculpture project" },
  {
    id: "9c1c711c-ad42-4f8c-afb0-163ec6297bff",
    label: "Slow UI",
    description: "Tag to mark PTNs where access was slow to load the UI or the UI was slow",
  },
  { id: "9c1c711c-aed8-41fc-a30d-ff84ee092131", label: "IOT", description: "Tag to identify PTNs generated by suspected IOT compromises" },
  { id: "9c1c711c-b424-47a8-b6a3-0839c7f8f193", label: "Pentest", description: "Confirmed pentest or red teaming activity" },
  {
    id: "9c1c711c-b578-4ff0-8250-3acccd6c7db1",
    label: "Potential Ransomware",
    description:
      "Potential cases of ransomware that we are unable to confirm during triage - for example, we see one write of a recovery file which could be a backup of an old ransomware",
  },
  { id: "9c1c711c-b6d7-4aa1-a587-5fdea2a4d0db", label: "ICS", description: "A tag for any PTN related to ICS activity or devices" },
  {
    id: "9c1c711c-b82e-46bb-967f-17f9696c19bf",
    label: "Ransomware",
    description: "Tag for activity which appears to relate to ransomware infections",
  },
  { id: "9c1c711c-b982-4db7-825c-2cd7b1d47878", label: "trial", description: "Tag for PTN trial alerts" },
  { id: "9c1c711c-baab-48ca-867f-926f16d25b4f", label: "ShortBirthday", description: "Tag to track the ShortBirthday project" },
  {
    id: "9c1c711c-bbbd-4cc2-9e2d-80decef8f8e2",
    label: "Old Ransomware",
    description:
      "Tag used to track detections of old remnants of ransomware, for example files being moved during a backup that were encrypted in a alert previously, and not relating to live or new ransomware",
  },
  {
    id: "9c1c711c-bcf4-4d39-bfb5-dfa626de0bac",
    label: "Customer Compliment",
    description:
      "Tag to be used when an analyst receives positive feedback or a congratulatory note from a customer as a result of alerting a PTN.",
  },
  {
    id: "9c1c711c-be11-4933-8e99-3afc1e19f3db",
    label: "Spot Report",
    description: "Tag used for PTN that trigger a crisis support investigation resulting in a spot report.",
  },
  {
    id: "9c1c711c-bf50-4c9d-ae04-2a4e1c5dc24c",
    label: "Attack Sim",
    description:
      "Activity that appears to originate from a planned attack simulation from a known  attack sim vendor, such as Cymulate, AttackIQ, Safealert, KnowB4, or others.",
  },
  {
    id: "9c1c711c-c0a2-40be-a8a9-11f7f57cc66c",
    label: "Closed Deal",
    description: "Used for POV-PTN that resulted in a closed deal following the initial alert.",
  },
  {
    id: "9c1c711c-c1b3-46ac-b31f-39e66c708fcd",
    label: "Customer Success Issue",
    description:
      "Tracks alerts where the customer has contacted the account team with an issue in relating to this PTN. Might be a delay, inadvertent resolve, etc.",
  },
  {
    id: "9c1c711c-c2dd-4c2a-bbc4-a2b643d83614",
    label: "Out-of-Hours Attack",
    description: "Attack occurring out-of-hours, such as 17:00 local on a Friday.",
  },
  {
    id: "9c1c711c-c41e-4b80-b057-95e832f1ad1a",
    label: "Cryptomining",
    description: "This scenario involved evidence of crypto-mining activity",
  },
  {
    id: "9c1c711c-c556-4e2f-aa2c-edd255a108d0",
    label: "Gitlab Exploit",
    description: "Accounts where CVE-2021-22205 was likely exploited",
  },
  {
    id: "9c1c711c-c677-4220-bf2f-5bca40909a25",
    label: "Log4Shell Exploit",
    description: "Confirmed compromises involving Log4Shell/Log4j vulnerability",
  },
  {
    id: "9c1c711c-c799-418d-9fa9-774ffa16e832",
    label: "Adversary Campaign",
    description: "Tag used when specific indicators are observed across multiple accounts.",
  },
  {
    id: "9c1c711c-c8ce-40e9-9ddd-9f9bfa56545c",
    label: "Device Review",
    description: "Devices that frequently alert the same models. Will be used to identify recurring in SOCStats in order to tune PTN.",
  },
  { id: "9c1c711c-c9f0-46ea-97f3-c4fbe3c894ad", label: "LinenMars", description: "Tag to track the LinenMars project" },
  {
    id: "9c1c711c-cb41-4efd-8793-7bfb2e3ce94b",
    label: "Flag To SOC Lead",
    description: "Indicates that this PTN should be flagged to a SOC lead",
  },
  { id: "9c1c711c-cc74-437d-8c7f-0805d5df9041", label: "RelaxingOutline", description: "Tag to track the RelaxingOutline project" },
  {
    id: "9c1c711c-cda3-4261-bd87-bfea1f3dbc4c",
    label: "Account Team Tipped",
    description: "Tag to imply that the issue has been escalated to account team",
  },
  { id: "9c1c711c-ceee-41e5-89a2-acd45582c908", label: "Interesting Find", description: "Generic tag to track interesting finds on SOC" },
  {
    id: "9c1c711c-d02b-4d24-98e3-93c776baccaa",
    label: "ATE Followup",
    description: "This PTN led to further work done for the customer in Ask the Expert, for example through a Spot report",
  },
  { id: "9c1c711c-d17f-4f31-96c9-34b8ad19ad62", label: "Tracking Issues", description: "This PTN exhibited signs of device tracking" },
  {
    id: "9c1c711c-d2dd-4749-add6-a63282320386",
    label: "Unidirectional",
    description: "This model was raised partly due to issues in visibility causing unidirectional visibility",
  },
  {
    id: "9c1c711c-d43c-4f9c-bbc8-c27c841e9c1a",
    label: "Non Approved Model",
    description: "This PTN was due to a misconfigured customer model  coming into SOC that shouldnt be expected",
  },
  {
    id: "9c1c711c-d55b-4db5-814c-aa310924f17a",
    label: "Custom Model",
    description: "This is the result of a bespoke model that has been customised for a customer and approved through SOC",
  },
];

export const mockAlerts = {
  current_page: 1,
  data: [
    {
      id: "9bc47fb0-bbee-4e5f-aaa0-3f00ab31c574",
      pbid: 9951966,
      client_id: "34cab97a-95e2-409c-9e24-5a3148999aad",
      appliance_id: null,
      hostname: "dt-7996-01",
      assignee_id: "7553b25d-406c-4739-8afb-029e9de6ecef",
      assignee_changed: "2024-10-18 15:52:56",
      terminal_comment_id: "9d46b855-1627-4c08-b0ec-270674fca9bd",
      time: "2024-04-09T17:04:05.000000Z",
      score: 0.61,
      status: "escalated",
      status_changed: "2024-10-18 16:57:38",
      model_uuid: "f61ca1ec-e5b9-46bb-bcd0-45b780cc9a17",
      model_name: "SaaS::Access::Unusual External Source for SaaS Credential Use",
      model_version: 58,
      model_modified_at: "2023-07-05 20:28:30",
      model_tags: null,
      device: {
        did: 31980,
        type: "saasprovider",
        hostname: "<USERNAME>::AzureActiveDirectory: <EMAIL_ADDRESS>",
      },
      triggered_components: [
        {
          metric_name: "saaslogin",
          metric_filters: {
            event: "Login",
            product: "Azure Portal",
            service: "AzureActiveDirectory",
            mfa_used: true,
            rare_saas_asn: 96,
            status_message: "Other.",
          },
        },
        {
          metric_name: "unusualcredentialuse",
          metric_filters: {
            asn: "AS25135 Vodafone Limited",
            country: "GB",
            message: "Unusual source for use of <EMAIL_ADDRESS> from AS25135 Vodafone Limited",
            strength: 100,
            direction: "in",
            source_ip: "85.255.234.23",
            age_of_source: 0,
            source_ip_raw: "85.255.234.23",
            rare_external_ip: 100,
            endpoint_public_ip: false,
            new_or_uncommon_occurrence: 100,
          },
        },
      ],
      aia_summary: null,
      created_at: "2024-04-09T17:09:41.000000Z",
      updated_at: "2025-01-08T14:53:11.000000Z",
      comments_count: 142,
      client: {
        id: "34cab97a-95e2-409c-9e24-5a3148999aad",
        codename: "TestClient",
      },
      assignee: {
        id: "7553b25d-406c-4739-8afb-029e9de6ecef",
        first_name: "Daisy",
        last_name: "Butler",
        email: "daisy.butler@darktrace.com",
        full_name: "Daisy Butler",
      },
      soc_tags: [
        {
          id: "9c1c711c-a4ed-4f87-892c-7bfc21e865b8",
          display_name: "Banking Trojan",
          description: "Tag for activity which appears to relate to banking trojan infections",
        },
      ],
    },
    {
      id: "9bc47fb2-2050-4c0b-a7d0-397cdd93dffa",
      pbid: 9951968,
      client_id: "34cab97a-95e2-409c-9e24-5a3148999aad",
      appliance_id: null,
      hostname: "dt-7996-01",
      assignee_id: "2fb12189-44e4-4e6e-9221-d9f3861a5efb",
      assignee_changed: "2024-10-18 16:58:48",
      terminal_comment_id: "9d46b8eb-12b8-4b0a-a861-96e765bf9cd5",
      time: "2024-04-09T17:04:06.000000Z",
      score: 0.9,
      status: "escalated",
      status_changed: "2024-10-18 16:59:16",
      model_uuid: "ef668f8f-aaa4-4f5e-a8b7-49b79aee2515",
      model_name: "Attack Path Modelling::Model Breach on Critical Attack Path",
      model_version: 22,
      model_modified_at: "2023-08-15 12:27:29",
      model_tags: null,
      device: {
        did: 31980,
        type: "saasprovider",
        hostname: "<USERNAME>::AzureActiveDirectory: <EMAIL_ADDRESS>",
      },
      triggered_components: [
        {
          metric_name: "dtmodelbreach",
          metric_filters: {
            message: "SaaS / Access / Unusual External Source for SaaS Credential Use",
            tagged_internal_source: "742",
            internal_source_device_type: "31",
          },
        },
      ],
      aia_summary: null,
      created_at: "2024-04-09T17:09:42.000000Z",
      updated_at: "2025-01-08T14:53:11.000000Z",
      comments_count: 0,
      client: {
        id: "34cab97a-95e2-409c-9e24-5a3148999aad",
        codename: "TestClient",
      },
      assignee: {
        id: "2fb12189-44e4-4e6e-9221-d9f3861a5efb",
        first_name: "Liam",
        last_name: "Mordue",
        email: "liam.mordue@darktrace.com",
        full_name: "Liam Mordue",
      },
      soc_tags: [],
    },
    {
      id: "9bc4826a-052f-4ddb-86e1-ccaee31fb713",
      pbid: 9951970,
      client_id: "34cab97a-95e2-409c-9e24-5a3148999aad",
      appliance_id: null,
      hostname: "dt-7996-01",
      assignee_id: "63462741-63ba-42b8-b1d1-37c8b33da843",
      assignee_changed: "2024-10-21 08:38:40",
      terminal_comment_id: "9d4c0f16-30ab-4ad9-9dad-ec59c3fd0997",
      time: "2024-04-09T17:17:12.000000Z",
      score: 0.03,
      status: "escalated",
      status_changed: "2024-10-21 08:39:21",
      model_uuid: "6b343d31-b927-415c-b167-de5ac346e10e",
      model_name: "testing internal source subnet label",
      model_version: 7,
      model_modified_at: "2023-05-22 09:46:31",
      model_tags: null,
      device: {
        ip: "10.145.0.15",
        did: 16537,
        tags: ["<USERNAME>", "Antigena All", "Antigena Compliance", "Antigena Significant Anomaly", "Critical Attack Path"],
        type: "mobile",
        ip_raw: "10.145.0.15",
        hostname: "mattermost.iotrap.com",
      },
      triggered_components: [
        {
          metric_name: "connections",
          metric_filters: {
            source_ip: "10.140.15.139",
            source_port: 33662,
            source_ip_raw: "10.140.15.139",
            destination_ip: "10.145.0.15",
            destination_ip_raw: "10.145.0.15",
          },
        },
      ],
      aia_summary: null,
      created_at: "2024-04-09T17:17:18.000000Z",
      updated_at: "2025-01-08T14:53:11.000000Z",
      comments_count: 0,
      client: {
        id: "34cab97a-95e2-409c-9e24-5a3148999aad",
        codename: "TestClient",
      },
      assignee: {
        id: "63462741-63ba-42b8-b1d1-37c8b33da843",
        first_name: "Colin",
        last_name: "Gardiner",
        email: "colin.gardiner@darktrace.com",
        full_name: "Colin Gardiner",
      },
      soc_tags: [],
    },
    {
      id: "9bc4965e-fd49-4b96-97e3-8963980370b0",
      pbid: 9951974,
      client_id: "34cab97a-95e2-409c-9e24-5a3148999aad",
      appliance_id: null,
      hostname: "dt-7996-01",
      assignee_id: "63462741-63ba-42b8-b1d1-37c8b33da843",
      assignee_changed: null,
      terminal_comment_id: "9d851034-bc2d-4f95-8d73-0bdd9806ca35",
      time: "2024-04-09T18:12:58.000000Z",
      score: 0.03,
      status: "escalated",
      status_changed: "2024-11-18 16:44:41",
      model_uuid: "6b343d31-b927-415c-b167-de5ac346e10e",
      model_name: "testing internal source subnet label",
      model_version: 7,
      model_modified_at: "2023-05-22 09:46:31",
      model_tags: null,
      device: {
        ip: "10.140.15.139",
        did: 3898,
        tags: ["<USERNAME> Tag", "Antigena All", "Human User", "Technical User"],
        type: "desktop",
        ip_raw: "10.140.15.139",
        hostname: "Antony-Lawson-Blue",
      },
      triggered_components: [
        {
          metric_name: "connections",
          metric_filters: {
            source_ip: "10.140.15.139",
            source_port: 51550,
            source_ip_raw: "10.140.15.139",
            destination_ip: "91.189.91.98",
            destination_ip_raw: "91.189.91.98",
          },
        },
      ],
      aia_summary: null,
      created_at: "2024-04-09T18:13:07.000000Z",
      updated_at: "2025-01-08T14:53:11.000000Z",
      comments_count: 1,
      client: {
        id: "34cab97a-95e2-409c-9e24-5a3148999aad",
        codename: "TestClient",
      },
      assignee: {
        id: "63462741-63ba-42b8-b1d1-37c8b33da843",
        first_name: "Colin",
        last_name: "Gardiner",
        email: "colin.gardiner@darktrace.com",
        full_name: "Colin Gardiner",
      },
      soc_tags: [],
    },
    {
      id: "9bc4abd7-3c58-4575-a1a9-8f97e5270556",
      pbid: 9951975,
      client_id: "34cab97a-95e2-409c-9e24-5a3148999aad",
      appliance_id: null,
      hostname: "dt-7996-01",
      assignee_id: "7553b25d-406c-4739-8afb-029e9de6ecef",
      assignee_changed: "2024-10-18 17:09:33",
      terminal_comment_id: "9d46bc9f-3f4f-4004-b161-958bacc903ed",
      time: "2024-04-09T19:13:00.000000Z",
      score: 0.03,
      status: "escalated",
      status_changed: "2024-10-18 17:09:38",
      model_uuid: "6b343d31-b927-415c-b167-de5ac346e10e",
      model_name: "testing internal source subnet label",
      model_version: 7,
      model_modified_at: "2023-05-22 09:46:31",
      model_tags: null,
      device: {
        ip: "10.140.15.139",
        did: 3898,
        tags: ["<USERNAME> Tag", "Antigena All", "Human User", "Technical User"],
        type: "desktop",
        ip_raw: "10.140.15.139",
        hostname: "Antony-Lawson-Blue",
      },
      triggered_components: [
        {
          metric_name: "connections",
          metric_filters: {
            source_ip: "10.140.15.139",
            source_port: 57678,
            source_ip_raw: "10.140.15.139",
            destination_ip: "91.189.91.96",
            destination_ip_raw: "91.189.91.96",
          },
        },
      ],
      aia_summary: null,
      created_at: "2024-04-09T19:13:09.000000Z",
      updated_at: "2025-01-08T14:53:11.000000Z",
      comments_count: 0,
      client: {
        id: "34cab97a-95e2-409c-9e24-5a3148999aad",
        codename: "TestClient",
      },
      assignee: {
        id: "7553b25d-406c-4739-8afb-029e9de6ecef",
        first_name: "Daisy",
        last_name: "Butler",
        email: "daisy.butler@darktrace.com",
        full_name: "Daisy Butler",
      },
      soc_tags: [],
    },
    {
      id: "9bc4b5f6-41b5-469e-8440-2728fe6f2153",
      pbid: 9951976,
      client_id: "34cab97a-95e2-409c-9e24-5a3148999aad",
      appliance_id: null,
      hostname: "dt-7996-01",
      assignee_id: "63462741-63ba-42b8-b1d1-37c8b33da843",
      assignee_changed: "2024-10-22 13:04:01",
      terminal_comment_id: "9d4ea0e7-6811-4005-8dbf-08bf0a8d3ae3",
      time: "2024-04-09T19:40:26.000000Z",
      score: 0.03,
      status: "escalated",
      status_changed: "2024-10-22 15:18:45",
      model_uuid: "6b343d31-b927-415c-b167-de5ac346e10e",
      model_name: "testing internal source subnet label",
      model_version: 7,
      model_modified_at: "2023-05-22 09:46:31",
      model_tags: null,
      device: {
        ip: "10.140.67.77",
        did: 132441,
        tags: ["<USERNAME>::brandon:", "Antigena All"],
        type: "server",
        ip_raw: "10.140.67.77",
      },
      triggered_components: [
        {
          metric_name: "connections",
          metric_filters: {
            source_ip: "10.140.15.139",
            source_port: 68,
            source_ip_raw: "10.140.15.139",
            destination_ip: "10.140.67.77",
            destination_ip_raw: "10.140.67.77",
          },
        },
      ],
      aia_summary: null,
      created_at: "2024-04-09T19:41:27.000000Z",
      updated_at: "2025-01-08T14:53:11.000000Z",
      comments_count: 0,
      client: {
        id: "34cab97a-95e2-409c-9e24-5a3148999aad",
        codename: "TestClient",
      },
      assignee: {
        id: "63462741-63ba-42b8-b1d1-37c8b33da843",
        first_name: "Colin",
        last_name: "Gardiner",
        email: "colin.gardiner@darktrace.com",
        full_name: "Colin Gardiner",
      },
      soc_tags: [],
    },
    {
      id: "9bc4c156-3bd3-427f-aa6b-c53213520a1d",
      pbid: 9951977,
      client_id: "34cab97a-95e2-409c-9e24-5a3148999aad",
      appliance_id: null,
      hostname: "dt-7996-01",
      assignee_id: "63462741-63ba-42b8-b1d1-37c8b33da843",
      assignee_changed: null,
      terminal_comment_id: "9d8af811-414e-4aeb-a01f-0e94ffc435fb",
      time: "2024-04-09T20:13:04.000000Z",
      score: 0.03,
      status: "escalated",
      status_changed: "2024-11-21 15:12:09",
      model_uuid: "6b343d31-b927-415c-b167-de5ac346e10e",
      model_name: "testing internal source subnet label",
      model_version: 7,
      model_modified_at: "2023-05-22 09:46:31",
      model_tags: null,
      device: {
        ip: "10.140.15.139",
        did: 3898,
        tags: ["<USERNAME> Tag", "Antigena All", "Human User", "Technical User"],
        type: "desktop",
        ip_raw: "10.140.15.139",
        hostname: "Antony-Lawson-Blue",
      },
      triggered_components: [
        {
          metric_name: "connections",
          metric_filters: {
            source_ip: "10.140.15.139",
            source_port: 44568,
            source_ip_raw: "10.140.15.139",
            destination_ip: "91.189.91.48",
            destination_ip_raw: "91.189.91.48",
          },
        },
      ],
      aia_summary: null,
      created_at: "2024-04-09T20:13:15.000000Z",
      updated_at: "2025-01-08T14:53:11.000000Z",
      comments_count: 1,
      client: {
        id: "34cab97a-95e2-409c-9e24-5a3148999aad",
        codename: "TestClient",
      },
      assignee: {
        id: "63462741-63ba-42b8-b1d1-37c8b33da843",
        first_name: "Colin",
        last_name: "Gardiner",
        email: "colin.gardiner@darktrace.com",
        full_name: "Colin Gardiner",
      },
      soc_tags: [],
    },
    {
      id: "9bc4cf9a-7b9b-4f60-b21a-afaec2ea81c4",
      pbid: 9951978,
      client_id: "34cab97a-95e2-409c-9e24-5a3148999aad",
      appliance_id: null,
      hostname: "dt-7996-01",
      assignee_id: "2fb12189-44e4-4e6e-9221-d9f3861a5efb",
      assignee_changed: "2024-10-22 15:18:34",
      terminal_comment_id: "9d97160a-ff0e-4b0d-a4cd-cb59d3e112c2",
      time: "2024-04-09T20:20:52.000000Z",
      score: 0.18,
      status: "escalated",
      status_changed: "2024-11-27 15:45:54",
      model_uuid: "01ef7095-46ef-4a07-aaa9-b36195cedb31",
      model_name: "Experimental::SaaS::Cloudflare Service Data Collect Model",
      model_version: 6,
      model_modified_at: "2023-06-06 11:58:57",
      model_tags: null,
      device: {
        did: 132459,
        type: "saasprovider",
        hostname: "<USERNAME>::Cloudflare: <EMAIL_ADDRESS>",
      },
      triggered_components: [
        {
          metric_name: "saasall",
          metric_filters: {
            event: "sso_login",
            service: "Cloudflare",
            asn_rarity: 0,
            resource_size: 0,
            unusual_saas_usage: 25,
            new_or_uncommon_occurrence: 100,
          },
        },
      ],
      aia_summary: null,
      created_at: "2024-04-09T20:53:09.000000Z",
      updated_at: "2025-01-08T14:53:11.000000Z",
      comments_count: 1,
      client: {
        id: "34cab97a-95e2-409c-9e24-5a3148999aad",
        codename: "TestClient",
      },
      assignee: {
        id: "2fb12189-44e4-4e6e-9221-d9f3861a5efb",
        first_name: "Liam",
        last_name: "Mordue",
        email: "liam.mordue@darktrace.com",
        full_name: "Liam Mordue",
      },
      soc_tags: [],
    },
    {
      id: "9bc4d6d6-163b-47c9-a081-b61e4c8d09a3",
      pbid: 9951979,
      client_id: "34cab97a-95e2-409c-9e24-5a3148999aad",
      appliance_id: null,
      hostname: "dt-7996-01",
      assignee_id: "7553b25d-406c-4739-8afb-029e9de6ecef",
      assignee_changed: "2024-10-22 15:44:19",
      terminal_comment_id: "9d8b283b-6739-4cdf-bb2e-cc5884102fae",
      time: "2024-04-09T21:13:12.000000Z",
      score: 0.03,
      status: "escalated",
      status_changed: "2024-11-21 17:26:49",
      model_uuid: "6b343d31-b927-415c-b167-de5ac346e10e",
      model_name: "testing internal source subnet label",
      model_version: 7,
      model_modified_at: "2023-05-22 09:46:31",
      model_tags: null,
      device: {
        ip: "10.140.15.139",
        did: 3898,
        tags: ["<USERNAME> Tag", "Antigena All", "Human User", "Technical User"],
        type: "desktop",
        ip_raw: "10.140.15.139",
        hostname: "Antony-Lawson-Blue",
      },
      triggered_components: [
        {
          metric_name: "connections",
          metric_filters: {
            source_ip: "10.140.15.139",
            source_port: 36778,
            source_ip_raw: "10.140.15.139",
            destination_ip: "91.189.91.97",
            destination_ip_raw: "91.189.91.97",
          },
        },
      ],
      aia_summary: null,
      created_at: "2024-04-09T21:13:22.000000Z",
      updated_at: "2025-01-08T14:53:12.000000Z",
      comments_count: 1,
      client: {
        id: "34cab97a-95e2-409c-9e24-5a3148999aad",
        codename: "TestClient",
      },
      assignee: {
        id: "7553b25d-406c-4739-8afb-029e9de6ecef",
        first_name: "Daisy",
        last_name: "Butler",
        email: "daisy.butler@darktrace.com",
        full_name: "Daisy Butler",
      },
      soc_tags: [],
    },
    {
      id: "9bc4e0de-0530-4ae6-9354-bf6f9819db7c",
      pbid: 9951980,
      client_id: "34cab97a-95e2-409c-9e24-5a3148999aad",
      appliance_id: null,
      hostname: "dt-7996-01",
      assignee_id: "63462741-63ba-42b8-b1d1-37c8b33da843",
      assignee_changed: "2024-10-22 14:54:00",
      terminal_comment_id: "9d4e98a6-00b4-442a-8ea1-7b1c2b6bb316",
      time: "2024-04-09T21:40:26.000000Z",
      score: 0.03,
      status: "escalated",
      status_changed: "2024-10-22 14:55:40",
      model_uuid: "6b343d31-b927-415c-b167-de5ac346e10e",
      model_name: "testing internal source subnet label",
      model_version: 7,
      model_modified_at: "2023-05-22 09:46:31",
      model_tags: null,
      device: {
        ip: "10.140.67.77",
        did: 132441,
        tags: ["<USERNAME>::brandon:", "Antigena All"],
        type: "server",
        ip_raw: "10.140.67.77",
      },
      triggered_components: [
        {
          metric_name: "connections",
          metric_filters: {
            source_ip: "10.140.15.139",
            source_port: 68,
            source_ip_raw: "10.140.15.139",
            destination_ip: "10.140.67.77",
            destination_ip_raw: "10.140.67.77",
          },
        },
      ],
      aia_summary: null,
      created_at: "2024-04-09T21:41:25.000000Z",
      updated_at: "2025-01-08T14:53:11.000000Z",
      comments_count: 0,
      client: {
        id: "34cab97a-95e2-409c-9e24-5a3148999aad",
        codename: "TestClient",
      },
      assignee: {
        id: "63462741-63ba-42b8-b1d1-37c8b33da843",
        first_name: "Colin",
        last_name: "Gardiner",
        email: "colin.gardiner@darktrace.com",
        full_name: "Colin Gardiner",
      },
      soc_tags: [],
    },
  ],
  first_page_url: "https://staging.login.darktrace.com/api/alerts?page=1",
  from: 1,
  last_page: 4226,
  last_page_url: "https://staging.login.darktrace.com/api/alerts?page=4226",
  links: [
    {
      url: null,
      label: "&laquo; Previous",
      active: false,
    },
    {
      url: "https://staging.login.darktrace.com/api/alerts?page=1",
      label: "1",
      active: true,
    },
    {
      url: "https://staging.login.darktrace.com/api/alerts?page=2",
      label: "2",
      active: false,
    },
    {
      url: "https://staging.login.darktrace.com/api/alerts?page=3",
      label: "3",
      active: false,
    },
    {
      url: "https://staging.login.darktrace.com/api/alerts?page=4",
      label: "4",
      active: false,
    },
    {
      url: "https://staging.login.darktrace.com/api/alerts?page=5",
      label: "5",
      active: false,
    },
    {
      url: "https://staging.login.darktrace.com/api/alerts?page=6",
      label: "6",
      active: false,
    },
    {
      url: "https://staging.login.darktrace.com/api/alerts?page=7",
      label: "7",
      active: false,
    },
    {
      url: "https://staging.login.darktrace.com/api/alerts?page=8",
      label: "8",
      active: false,
    },
    {
      url: "https://staging.login.darktrace.com/api/alerts?page=9",
      label: "9",
      active: false,
    },
    {
      url: "https://staging.login.darktrace.com/api/alerts?page=10",
      label: "10",
      active: false,
    },
    {
      url: null,
      label: "...",
      active: false,
    },
    {
      url: "https://staging.login.darktrace.com/api/alerts?page=4225",
      label: "4225",
      active: false,
    },
    {
      url: "https://staging.login.darktrace.com/api/alerts?page=4226",
      label: "4226",
      active: false,
    },
    {
      url: "https://staging.login.darktrace.com/api/alerts?page=2",
      label: "Next &raquo;",
      active: false,
    },
  ],
  next_page_url: "https://staging.login.darktrace.com/api/alerts?page=2",
  path: "https://staging.login.darktrace.com/api/alerts",
  per_page: 10,
  prev_page_url: null,
  to: 10,
  total: 42254,
};

export const mockComponents = [
  {
    device: {
      ip: "192.168.0.1",
      did: 892701675,
      hostname: "test.domain.info",
      typelabel: "Tablet",
    },
    triggeredFilters: [
      {
        trigger: {
          value: "Device / Anomaly Indicators / RDP Bruteforce Indicator",
        },
        filterType: "New or uncommon occurence",
      },
      {
        trigger: {
          value: "1070244618",
        },
        filterType: "Message",
      },
      {
        trigger: {
          value: "Device / Attack and Recon Tools",
        },
        filterType: "Internal destination device name",
      },
      {
        trigger: {
          tag: {
            tid: 68,
            data: {
              auto: false,
              color: 177,
              visibility: "Public",
              description: "Voluptas consequatur delectus minus est minus.",
            },
            name: "Gateway",
            thid: 68,
            expiry: 0,
            isReferenced: false,
          },
          value: 68,
        },
        filterType: "Tagged internal source",
      },
    ],
  },
  {
    device: {
      ip: "192.168.0.1",
      did: 274085773,
      hostname: "test.another.info",
      typelabel: "Wifi",
    },
    triggeredFilters: [
      {
        trigger: {
          value: "Device / Attack and Recon Tools",
        },
        filterType: "New or uncommon occurence",
      },
      {
        trigger: {
          value: "Device / Attack and Recon Tools",
        },
        filterType: "Message",
      },
      {
        trigger: {
          value: "6",
        },
        filterType: "Age of source",
      },
      {
        trigger: {
          value: "Anomalous Connection / SMB Enumeration",
        },
        filterType: "Age of source",
      },
      {
        trigger: {
          tag: {
            tid: 43,
            data: {
              auto: false,
              color: 32,
              visibility: "Public",
              description: "Quidem dolorem quasi quia quibusdam sint qui.",
            },
            name: "Re-Activated Device",
            thid: 43,
            expiry: 0,
            isReferenced: true,
          },
          value: 43,
        },
        filterType: "Tagged internal source",
      },
    ],
  },
  {
    device: {
      ip: "192.0.168.1",
      did: 224064457,
      hostname: "example.host.com",
      typelabel: "ICS PLC",
    },
    triggeredFilters: [
      {
        trigger: {
          value: "Device / Network Scan",
        },
        filterType: "Internal destination device name",
      },
      {
        trigger: {
          value: "Device / Network Scan",
        },
        filterType: "Matching metric list",
      },
      {
        trigger: {
          value: "Device / Attack and Recon Tools",
        },
        filterType: "Message",
      },
      {
        trigger: {
          value: "Device / Anomaly Indicators / RDP Bruteforce Indicator",
        },
        filterType: "Message",
      },
      {
        trigger: {
          value: "185578923",
        },
        filterType: "Internal destination device name",
      },
      {
        trigger: {
          value: "Device / Network Scan",
        },
        filterType: "Internal destination device name",
      },
      {
        trigger: {
          tag: {
            tid: 78,
            data: {
              auto: false,
              color: 101,
              visibility: "Public",
              description: "Dolor exercitationem reprehenderit illo quod possimus sed maiores tempore.",
            },
            name: "Re-Activated Device",
            thid: 78,
            expiry: 0,
            isReferenced: true,
          },
          value: 78,
        },
        filterType: "Tagged internal source",
      },
    ],
  },
];

export const mockIntel = [
  {
    id: "144d9aef-ca7e-44c7-8254-3a6fd1d20155",
    rtline_id: 1820860274,
    client_id: "9ca78d62-e2e2-4df7-813c-b7f69cc74081",
    content:
      "Omnis quisquam aut quia. Et repellat et consequuntur error. Dolores amet laborum reprehenderit. Eos blanditiis et ipsum et aut nobis voluptas voluptatem.",
    type: "Technical",
    author_name: "Example User",
    created_at: "2022-12-07 09:22:48",
  },
  {
    id: "1b3fe4ab-ee30-4a10-87f4-bf61e7efcf7c",
    rtline_id: 530488052,
    client_id: "9ca78d62-e2e2-4df7-813c-b7f69cc74081",
    content:
      "Et deleniti corporis voluptatem ea sit. Cum earum non saepe illum id voluptatum ut nihil. Facilis sit rerum dolorum. Et doloremque rerum praesentium a eveniet.",
    type: "SOC",
    author_name: "Example User",
    created_at: "2023-06-10 19:24:37",
  },
  {
    id: "c1c12aa8-400d-4dfd-bb25-e908c0433615",
    rtline_id: 1685691544,
    client_id: "9ca78d62-e2e2-4df7-813c-b7f69cc74081",
    content: "Necessitatibus itaque vel voluptatem id vitae. Perferendis atque tempore tempore voluptatem.",
    type: "General",
    author_name: "Test User",
    created_at: "2024-04-03 13:47:28",
  },
];

export const mockEscalation = {
  id: "9d86ec27-7f68-42a8-b8b6-049168f86985",
  alert_id: "9d86ec26-d3a9-4c83-b49a-f7e14710d695",
  escalated_by: "9bb82a0b-433e-4fb0-b6db-0f1afb68036b",
  terminal_comment_content:
    "Ipsam non laudantium mollitia perferendis molestias temporibus sed. Quia sed nemo consequatur est quisquam inventore non et. Molestias rerum sapiente deserunt consectetur. Cupiditate qui odio non eos illo sed sed ut.",
  mdr_actions: null,
  created_at: "2024-11-19T14:55:31.000000Z",
  updated_at: "2024-11-19T14:55:31.000000Z",
  alert: {
    id: "9d86ec26-d3a9-4c83-b49a-f7e14710d695",
    pbid: 5924,
    client_id: "9d86ec24-7b83-457c-ac00-c56d1d7de495",
    appliance_id: "9d86ec25-3a6f-453f-934a-ff8659633b3d",
    hostname: "dt-83180-91",
    assignee_id: "9bb82a0b-433e-4fb0-b6db-0f1afb68036b",
    assignee_changed: "2024-11-17 09:35:52",
    terminal_comment_id: "9d86ec27-a30b-4b2f-a597-32dab2d32c6d",
    time: "2024-11-19T13:54:03.000000Z",
    score: 0.44,
    status: "escalated",
    status_changed: null,
    model_uuid: "91c5b0fb-90b2-3955-b66b-ffc984e10de6",
    model_name: "Anomalous File::Anomalous Octet Stream",
    model_version: 33,
    model_modified_at: "2024-11-17 14:55:31",
    model_tags: null,
    device: {
      ip: "229.253.179.57",
      did: 9,
      type: "laptop",
      ip_raw: "229.253.179.57",
      hostname: "kling186.zemlak.com",
    },
    triggered_components: [
      {
        metric_name: "externalclientdatatransfervolume",
        metric_filters: {
          asn: "AS00001 AMAZON-01",
          country: "UK",
          same_ip: false,
          direction: "out",
          destination_ip: "127.0.0.1",
          destination_ip_raw: "127.0.0.1",
        },
      },
      {
        metric_name: "saaslogin",
        metric_filters: { service: "Office365" },
      },
    ],
    aia_summary:
      "----- capturelosstoomuchloss -----\n\nAI Analyst Score: 9%\n\nThe device kling186.zemlak.com was observed making unusual internal connections to a wide range of devices over multiple administrative channels.\n\nThough this behaviour could be the result of legitimate remote access or administration, it could also be a sign of attempted lateral movement by a compromised machine.\n\nConsequently, if this activity was not expected, the security team may wish to investigate further.\n\n[ Model Alert: capturelosstoomuchloss 9% - Alert URI: /#modelalert/5924 ]\n\nAlerting Device\n\n\u2022 kling186.zemlak.com \u00b7 229.253.179.57\n\u2022 Username observed prior to activity: rapid\n\nSSH Activity\n\n\u2022 Time: 2024-11-19 12:54:03 - 13:54:03 UTC\n\u2022 Number of unique IPs: 254\n\u2022 Targeted IP range: 229.253.179.57/24\n\u2022 Destination port: 22\n\u2022 Connection count: 740\n\u2022 Percentage successful: 35.5%\n\nWinRM Activity\n\n\u2022 Time: 2024-11-19 12:54:03 - 13:54:03 UTC\n\u2022 Number of unique IPs: 67\n\u2022 Targeted IP range: 229.253.179.57/24\n\u2022 Destination ports: 5985, 5986\n\u2022 Connection count: 1,846\n\u2022 Percentage successful: 89.7%",
    created_at: "2024-11-19T14:55:31.000000Z",
    updated_at: "2024-11-19T14:55:32.000000Z",
  },
  escalator: {
    id: "9bb82a0b-433e-4fb0-b6db-0f1afb68036b",
    first_name: "Lambda",
    last_name: null,
    client_id: null,
    identity_provider: null,
    email: "lambda.cloud-soc@darktrace.com",
    full_name: "Lambda",
  },
  communications: [
    {
      id: 23,
      escalation_id: "9d86ec27-7f68-42a8-b8b6-049168f86985",
      medium: "email",
      recipient: "qbarrows@yahoo.com",
      state: "failure",
      successfully_sent_at: null,
      created_at: "2024-11-19T14:55:31.000000Z",
      updated_at: "2024-11-19T14:55:31.000000Z",
    },
    {
      id: 24,
      escalation_id: "9d86ec27-7f68-42a8-b8b6-049168f86985",
      medium: "email",
      recipient: "sandrine.smitham@terry.com",
      state: "pending",
      successfully_sent_at: null,
      created_at: "2024-11-19T14:55:31.000000Z",
      updated_at: "2024-11-19T14:55:31.000000Z",
    },
    {
      id: 25,
      escalation_id: "9d86ec27-7f68-42a8-b8b6-049168f86985",
      medium: "email",
      recipient: "zcrist@gmail.com",
      state: "success",
      successfully_sent_at: "2024-11-15 19:45:49",
      created_at: "2024-11-19T14:55:31.000000Z",
      updated_at: "2024-11-19T14:55:31.000000Z",
    },
    {
      id: 26,
      escalation_id: "9d86ec27-7f68-42a8-b8b6-049168f86985",
      medium: "email",
      recipient: "hkemmer@gmail.com",
      state: "pending",
      successfully_sent_at: null,
      created_at: "2024-11-19T14:55:31.000000Z",
      updated_at: "2024-11-19T14:55:31.000000Z",
    },
  ],
};

export const mockShifts = {
  slot: 5,
  isUsShift: true,
  handover: "2025-01-06 21:00:00",
  isCurrentUserOnShift: false,
  contract: {
    current: [
      {
        name: "John Doe",
        email: "john.doe@darktrace.com",
        id: "1234",
      },
      {
        name: "Joe Bloggs",
        email: "joe.bloggs@darktrace.com",
        id: "1111",
      },
      {
        name: "Principal Analyst",
        email: "principal.analyst@darktrace.com",
        id: "2222",
      },
      {
        name: "Sample Analyst",
        email: "sample.analyst@darktrace.com",
        id: "3333",
      },
      {
        name: "Daisy Butler",
        email: "another.analyst@darktrace.com",
        id: "18e04165-27b6-4134-a650-9d6d47bcd3e1",
      },
      {
        name: "Great Analyst",
        email: "great.analyst@darktrace.com",
        id: "5555",
      },
      {
        name: "Superstar Analyst",
        email: "Superstar.analyst@darktrace.com",
        id: "3434",
      },
    ],
    next: [
      {
        name: "Some Person",
        email: "some.person@darktrace.com",
        id: "4321",
      },
      {
        name: "Bill Placeholder",
        email: "bill.placeholder@darktrace.com",
        id: "2345",
      },
      {
        name: "Valued Employee",
        email: "valued.employee@darktrace.com",
        id: "3456",
      },
    ],
  },
  pov: {
    current: [
      {
        name: "Jacob Analyst",
        email: "jacob.analyst@darktrace.com",
        id: "7777",
      },
    ],
    next: [
      {
        name: "Jacob Analyst",
        email: "jacob.analyst@darktrace.com",
        id: "8888",
      },
    ],
  },
};
