import { Tooltip } from "@darktrace/ui-components";
import React from "react";
import styled from "styled-components";

const StyledCount = styled.div`
  background-color: var(--dt-ui-card-bg);
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
  gap: 0.4rem;
  display: flex;
  i {
    color: var(--color);
  }
`;

export function TileBadge({ data, color, tooltip, noIcon, text = "", icon = "" }) {
  return (
    <Tooltip text={tooltip}>
      <StyledCount style={{ "--color": color }}>
        {noIcon ?? <i className={`fas fa-${icon || "warning"}`}></i>}
        {text ?? <div>{text}</div>}
        <span>{data?.pages[0]?.total?.toLocaleString() || 0}</span>
      </StyledCount>
    </Tooltip>
  );
}
