import { Card } from "@darktrace/ui-components";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { logoutUser } from "../logic/api.js";

const StyledLogoutPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
  height: 100%;

  .content {
    min-width: 32rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    align-items: center;
  }
`;

export function LogoutPage() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => logoutUser().then(() => navigate("/login")), 3000);
  }, []);

  return (
    <StyledLogoutPage>
      <Card title={t(`You have been logged out`)}>
        <div className="content">
          <span className="description">{t(`You will be redirected to the login page.`)}</span>
        </div>
      </Card>
    </StyledLogoutPage>
  );
}
