import { Button, Card, Dropdown } from "@darktrace/ui-components";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import {
  useAddClientApplicationScopeMutation,
  useClientApplicationsQuery,
  useMultiInstanceProductScopesQuery,
  useRemoveClientApplicationScopeMutation,
} from "../../logic/api.js";
import { useActiveClientId } from "../../logic/hooks.js";
import { ConfirmDeleteApplicationModal } from "./ConfirmDeleteApplicationModal.jsx";
import { ClientApplicationsSidepanel } from "./ClientApplicationsSidepanel.jsx";
import { useDispatch } from "react-redux";
import { newToast } from "../../logic/store.js";

const StyledClientApplications = styled.div`
  height: 100%;

  display: flex;
  gap: 2rem;

  .integrations-panel {
    flex-grow: 1;

    .grid {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;

      row-gap: 0.8rem;
      column-gap: 2rem;

      .instance-name {
        font-weight: 700;
        font-size: 1.4rem;
      }
    }
  }
`;

export function ClientApplications() {
  const activeClientId = useActiveClientId();
  const dispatch = useDispatch();

  const [selectedApplicationId, setSelectedApplicationId] = useState();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const { data: clientApplications = [] } = useClientApplicationsQuery({ clientId: activeClientId });
  const { data: mulitInstanceProductScopes } = useMultiInstanceProductScopesQuery({ clientId: activeClientId });
  console.log(`🚀 ~ ClientApplications ~ mulitInstanceProductScopes:`, mulitInstanceProductScopes);

  const selectedApplication = clientApplications.find((application) => application.id === selectedApplicationId);

  const { mutate: addClientApplicationScope, isPending: isAddingClientApplicationScope } = useAddClientApplicationScopeMutation({
    ccaId: selectedApplicationId,
    clientId: activeClientId,
  });

  const { mutate: removeClientApplicationScope, isPending: isRemovingClientApplicationScope } = useRemoveClientApplicationScopeMutation({
    ccaId: selectedApplicationId,
    clientId: activeClientId,
  });

  function toggleClientApplicationScope({ value, groupId, instanceId }) {
    if (value) {
      addClientApplicationScope({ productGroup: groupId, instanceId });
    } else {
      const scopeId = selectedApplication.scopes.find((scope) => scope.instanceId === instanceId && scope.scope === groupId)?.id;

      if (!scopeId) {
        dispatch(newToast({ variant: "error", title: "Could not remove scope" }));
        return;
      }

      removeClientApplicationScope({ scopeId, instanceId });
    }
  }

  return (
    <StyledClientApplications>
      <ClientApplicationsSidepanel selectedApplicationId={selectedApplicationId} setSelectedApplicationId={setSelectedApplicationId} />

      {selectedApplicationId && (
        <Card
          className="integrations-panel"
          title={t(`Application Details`)}
          titleRight={
            <Button variant="secondary" icon="trash" onClick={() => setShowConfirmDeleteModal(true)}>
              Delete
            </Button>
          }
        >
          <div className="grid">
            {mulitInstanceProductScopes.map(({ instanceId, instanceName, groups }) => {
              const dropdownItems = groups.map((group) => ({
                id: group,
                label: group,
                selected: selectedApplication.scopes.some((scope) => scope.instanceId === instanceId && scope.scope === group),
              }));

              return (
                <Fragment key={instanceId}>
                  <span className="instance-name">{instanceName}</span>

                  <Dropdown
                    items={dropdownItems}
                    checkbox
                    disabled={isAddingClientApplicationScope || isRemovingClientApplicationScope}
                    hideSelectAll
                    onSelect={(groupId) => {
                      const newValue = !groups.find((group) => group.id === groupId)?.selected;
                      toggleClientApplicationScope({ value: newValue, groupId, instanceId });
                    }}
                  />
                </Fragment>
              );
            })}
          </div>
        </Card>
      )}

      <ConfirmDeleteApplicationModal
        open={showConfirmDeleteModal}
        onClose={() => setShowConfirmDeleteModal(false)}
        selectedApplication={selectedApplication}
        setSelectedApplicationId={setSelectedApplicationId}
      />
    </StyledClientApplications>
  );
}
