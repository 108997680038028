import React, { useState } from "react";
import { useActiveClientId } from "../../logic/hooks.js";
import { useClientApplicationsQuery } from "../../logic/api.js";
import { Button, CollapsableCard, Section, Tile, TileContainer } from "@darktrace/ui-components";
import { ClientApplicationDetailsModal } from "./ClientApplicationDetailsModal.jsx";
import styled from "styled-components";

const StyledClientApplicationsSidepanel = styled(CollapsableCard)`
  flex-shrink: 0;

  .content {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    height: 100%;

    .create-application-btn {
      width: 100%;
    }

    .applications-section {
      flex-basis: 0;
      flex-grow: 1;
      overflow-y: auto;

      .applications {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        padding-right: 0.6rem;
      }
    }
  }
`;

export function ClientApplicationsSidepanel({ selectedApplicationId, setSelectedApplicationId }) {
  const [collapsed, setCollapsed] = useState(false);
  const [showClientApplicationDetailsModal, setShowClientApplicationDetailsModal] = useState(false);

  const activeClientId = useActiveClientId();
  const { data: clientApplications = [] } = useClientApplicationsQuery({ clientId: activeClientId });

  return (
    <StyledClientApplicationsSidepanel
      className="integrations-sidebar"
      title={t(`Darktrace ActiveAI Security Portal`)}
      width={400}
      collapsed={collapsed}
      onClickCollapsedToggle={() => setCollapsed((prev) => !prev)}
    >
      {!collapsed && (
        <div className="content">
          <Button
            className="create-application-btn"
            icon="plus"
            size="small"
            onClick={() => setShowClientApplicationDetailsModal((prev) => !prev)}
          >
            {t(`Create New Application`)}
          </Button>

          <Section title={t(`Applications`)} className="applications-section">
            <TileContainer className="applications">
              {clientApplications.map((application) => (
                <Tile
                  key={application.id}
                  active={selectedApplicationId === application.id}
                  noColorBar
                  header={`${application.name} - ${application.scopes.length} ${application.scopes.length === 1 ? t(`scope`) : t(`scopes`)}`}
                  onClick={() => setSelectedApplicationId(application.id)}
                />
              ))}
            </TileContainer>
          </Section>
        </div>
      )}

      <ClientApplicationDetailsModal
        open={showClientApplicationDetailsModal}
        onClose={() => setShowClientApplicationDetailsModal(false)}
        selectedApplicationId={null}
        setSelectedApplicationId={setSelectedApplicationId}
      />
    </StyledClientApplicationsSidepanel>
  );
}
