import { Card, FlatCard, GhostElement, Pill, Section } from "@darktrace/ui-components";
import React, { useEffect, useState } from "react";
import { ShiftButtonGroup } from "../shared/ShiftButtonGroup.jsx";
import { useAnalystShifts, usePTNAlerts, useUserProfileInfo } from "../../../logic/api";
import { useActiveClientId, useQueryParams } from "../../../logic/hooks";
import styled from "styled-components";
import { createSearchParams, useNavigate } from "react-router-dom";
import { TileBadge } from "../Sidebar/TileBadge.jsx";
import { alertStatus, endOfToday, startofToday } from "../utils.js";
import dayjs from "dayjs";

const StyledCard = styled(Card)`
  .dt-ui-card__contents {
    min-height: 20rem;
    .dt-ui-ghost-element {
      min-height: 20rem;
    }
  }
  .analyst-container {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      span:last-of-type {
        margin-left: auto;
      }
    }
  }
`;

const StyledRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  text-transform: initial;
  letter-spacing: normal;
  font-size: 1.2rem;
`;

const StyledListItem = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.2rem;
`;

function AnalystCard({ name, id, type }) {
  const navigate = useNavigate();
  const activeClientId = useActiveClientId();
  const { queryParams } = useQueryParams({ defaultQueryParams: { clientId: activeClientId } });
  const statuses = ["escalated", "inprogress", "resolved"];
  const params = {
    clientId: activeClientId,
    filters: { assignee_id: id, status_changed_start_time: startofToday, status_changed_end_time: endOfToday },
  };

  const { data: assignedAlerts } = usePTNAlerts({ ...params, filters: { ...params.filters, statuses: ["inprogress"] } });
  const { data: resolvedAlerts } = usePTNAlerts({ ...params, filters: { ...params.filters, statuses: ["resolved"] } });
  const { data: escalatedAlerts } = usePTNAlerts({ ...params, filters: { ...params.filters, statuses: ["escalated"] } });

  const noAlertsToShow = !(assignedAlerts?.pages[0].total + resolvedAlerts?.pages[0].total + escalatedAlerts?.pages[0].total);

  function handleHeaderClick(id) {
    const queryParamsToKeep = { ...queryParams, analystId: id };
    if (queryParams.analystId === id) {
      delete queryParamsToKeep.analystId;
    }
    navigate({ pathname: "overview/analysts", search: createSearchParams(queryParamsToKeep).toString() });
  }

  function formatLabel(label) {
    return label.replace("In Progress", "Assigned").replace("Alert", "");
  }

  return (
    <FlatCard
      titleRight={
        <StyledRight>
          {type === "pov" ? <Pill text={t(`Trial`)} /> : null}
          {statuses.map((status) => {
            const { label, color } = alertStatus[status];
            const data = status === "escalated" ? escalatedAlerts : status === "inprogress" ? assignedAlerts : resolvedAlerts;
            return data?.pages[0].total ? (
              <TileBadge data={data} text={formatLabel(label) + ": "} icon="circle-small" key={status} color={color} />
            ) : null;
          })}
        </StyledRight>
      }
      expandable
      onHeaderClick={() => handleHeaderClick(id)}
      expanded={id === queryParams.analystId}
      icon="user far"
      title={name}
      key={id}
    >
      {noAlertsToShow ? (
        <div>{t(`No alerts to show for this shift.`)}</div>
      ) : (
        statuses.map((status) => {
          const { label } = alertStatus[status];
          const data = status === "escalated" ? escalatedAlerts : status === "inprogress" ? assignedAlerts : resolvedAlerts;
          const sortedData = data?.pages[0].data?.length
            ? data?.pages[0].data.sort((a, b) => (dayjs(a.status_changed).isAfter(dayjs(b.status_changed)) ? -1 : 1))
            : [];

          return (
            <div key={status}>
              {sortedData.length ? (
                <>
                  <Section title={formatLabel(label)} />
                  <ul>
                    {sortedData.map(({ id, status_changed, model_name }) => {
                      return (
                        <StyledListItem key={id}>
                          <span>#{id}</span>
                          <span>{model_name || ""}</span>
                          <span>{dayjs(status_changed).format("ddd DD MMM YYYY, HH:mm")}</span>
                        </StyledListItem>
                      );
                    })}
                  </ul>
                </>
              ) : null}
            </div>
          );
        })
      )}
    </FlatCard>
  );
}

export function AnalystOverview() {
  const activeClientId = useActiveClientId();
  const { queryParams } = useQueryParams({ defaultQueryParams: { clientId: activeClientId } });
  const { data: userProfileInfo } = useUserProfileInfo();
  const yourUserId = userProfileInfo?.user?.id;
  const { data: analystShifts, isLoading, isError } = useAnalystShifts({ clientId: activeClientId, userId: yourUserId });
  const isCurrent = !queryParams.analystId ? null : !!analystShifts?.current?.find((x) => x.id === queryParams.analystId);
  const [shiftView, setShiftView] = useState("current");

  useEffect(() => {
    if (isCurrent === null) return;
    setShiftView(isCurrent ? "current" : "next");
  }, [isCurrent]);

  return (
    <StyledCard
      title={t(`SOC Analysts`)}
      icon="user-shield far"
      titleRight={<ShiftButtonGroup active={shiftView} handover={analystShifts?.handover} setActive={setShiftView} />}
    >
      {isLoading ? <GhostElement /> : null}
      {isError ? <div>No analysts to show.</div> : null}
      {analystShifts ? (
        <div className="analyst-container">
          {analystShifts[shiftView].map(({ id, name, type }) => {
            return <AnalystCard key={id} {...{ id, name, type }} />;
          })}
        </div>
      ) : null}
    </StyledCard>
  );
}
