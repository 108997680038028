import React from "react";
import { AnalystTile } from "./AnalystTile.jsx";
import { Pill } from "@darktrace/ui-components";
import styled from "styled-components";

const StyledContainer = styled.div`
  padding: 0;
  height: 30rem;
  overflow: scroll;
  gap: 0.8rem;
  display: flex;
  flex-direction: column;
`;

export function AnalystList({ analystShifts, shiftView, activeId, onClick = () => {}, disabled = false }) {
  return (
    <StyledContainer>
      {analystShifts ? (
        analystShifts[shiftView].map(({ id, name, type }) => {
          return (
            <AnalystTile
              key={id}
              name={name}
              active={activeId === id}
              onClick={() => onClick(id)}
              right={type === "pov" ? <Pill text="Trial" /> : null}
              disabled={disabled}
            />
          );
        })
      ) : (
        <div>{t(`No analysts to show.`)}</div>
      )}
    </StyledContainer>
  );
}
