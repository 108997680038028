import React, { useState } from "react";
import { Button, Input, ModalCard } from "@darktrace/ui-components";
import styled from "styled-components";
import { useActiveClientId } from "../../logic/hooks.js";
import { useClientApplicationsQuery, useCreateClientApplicationMutation } from "../../logic/api.js";

const StyledApplicationDetailsModal = styled(ModalCard)`
  .content {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .top {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      .grid {
        display: grid;
        gap: 0.8rem 0.4rem;
        grid-template-columns: auto 1fr;

        .field-name {
          position: relative;
          top: 0.7rem;
          font-weight: 700;
        }

        .field-value {
          width: 100%;
        }
      }
    }

    .action-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 0.8rem;
    }
  }
`;

export function ClientApplicationDetailsModal({ open, onClose, selectedApplicationId, setSelectedApplicationId }) {
  const modalRoot = document.querySelector("#modal-root");

  const activeClientId = useActiveClientId();
  const [name, setName] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const { data: clientApplications = [] } = useClientApplicationsQuery({ clientId: activeClientId });

  const isNameValid =
    name.length > 0 && !clientApplications.some((application) => application.name === name && application.id !== selectedApplicationId);

  const { mutateAsync: createClientApplication } = useCreateClientApplicationMutation({
    clientId: activeClientId,
    displayName: name,
  });

  function handleChangeName(value) {
    setName(value);
    setShowErrorMessage(false);
  }

  function handleSave() {
    if (!isNameValid) return setShowErrorMessage(true);
    if (selectedApplicationId) {
      // editClientApplication().then(onClose);
    } else {
      createClientApplication().then((application) => {
        onClose();
        setSelectedApplicationId(application.ccaId);
      });
    }
  }

  return (
    <StyledApplicationDetailsModal
      open={open}
      modalRoot={modalRoot}
      onClose={onClose}
      title={selectedApplicationId ? t(`Edit Application`) : t(`Create New Application`)}
    >
      <div className="content">
        <div className="top">
          <div className="grid">
            <span className="field-name">{t(`Name`)}:</span>
            <Input
              className="field-value"
              value={name}
              onChange={handleChangeName}
              valid={showErrorMessage ? (isNameValid ? undefined : false) : undefined}
              invalidMsg={name.length === 0 ? t(`Name field must not be empty`) : t(`Name is already taken`)}
              placeholder={t(`Application Name`)}
            />
          </div>
        </div>

        <div className="action-buttons">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>

          <Button disabled={showErrorMessage && !isNameValid} onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </StyledApplicationDetailsModal>
  );
}
