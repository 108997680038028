import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { usePTNAlerts } from "../../../logic/api";
import { useActiveClientId, useQueryParams } from "../../../logic/hooks";
import { CalendarRange, Card, GhostElement, Tile, TileContainer } from "@darktrace/ui-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EscalationCard } from "./EscalationCard.jsx";
import dayjs from "dayjs";
import { checkForPresetMatch, defaultEscalatedTabFilters, now } from "../utils.js";

const StyledEscalations = styled.div`
  height: 100%;
  .dt-ui-card__contents {
    min-height: 10rem;
    .dt-ui-tile-container {
      height: 100%;
      .dt-ui-ghost-element {
        min-height: 8rem;
      }
      .wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        .dt-ui-tile__body-left {
          display: grid;
          grid-template-columns: 1fr 1fr auto;
          justify-content: space-evenly;
          align-items: center;
          > div {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
`;

export function Escalations() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const activeClientId = useActiveClientId();

  const { queryParams, updateQueryParams } = useQueryParams({
    defaultQueryParams: {
      clientId: activeClientId,
      ...defaultEscalatedTabFilters,
    },
  });

  const statuses = queryParams.statuses?.split(",") || null;
  const status_changed_start_time = queryParams.status_changed_start_time || null;
  const status_changed_end_time = queryParams.status_changed_end_time || null;

  const [selectedStartDate, setSelectedStartDate] = useState(status_changed_start_time);
  const [selectedEndDate, setSelectedEndDate] = useState(status_changed_end_time);
  const [selectedPreset, setSelectedPreset] = useState(checkForPresetMatch(selectedStartDate, selectedEndDate));

  const {
    data: escalatedAlerts,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = usePTNAlerts({
    clientId: activeClientId,
    filters: { statuses, status_changed_start_time, status_changed_end_time },
  });

  const timePeriods = [
    { id: "Last 24 Hours", amount: 24, unit: "hours" },
    { id: "Last 3 Days", amount: 3, unit: "days" },
    { id: "Last 7 Days", amount: 7, unit: "days" },
    { id: "Last 2 Weeks", amount: 2, unit: "weeks" },
    { id: "Last 4 Weeks", amount: 4, unit: "weeks" },
    { id: "Last 6 Weeks", amount: 6, unit: "weeks" },
    { id: "Last 8 Weeks", amount: 55, unit: "days" },
  ];

  const presets = timePeriods.map(({ id, amount, unit }) => ({
    id,
    label: id,
    startTime: dayjs().utc().startOf("day").subtract(amount, unit),
    selected: selectedPreset === id,
  }));

  // Infinite scrolling logic
  const observerRef = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading || isFetchingNextPage) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage],
  );

  // Sync start and end time states when params are updates
  useEffect(() => {
    setSelectedStartDate(status_changed_start_time);
    setSelectedEndDate(status_changed_end_time);
  }, [status_changed_start_time, status_changed_end_time]);

  return (
    <StyledEscalations>
      {id ? (
        <EscalationCard />
      ) : (
        <Card
          icon="flag far"
          title={t(`Escalated Alerts`)}
          titleRight={
            <CalendarRange
              maxDate={now}
              // 8 weeks less one day as backend includes today as part of the 8 week limit
              minDate={dayjs().utc().subtract(55, "days").format("YYYY-MM-DDTHH:mm:ss") + ".000000Z"}
              selectedStartDate={selectedStartDate}
              onSelectStartDate={(dateMs) => {
                setSelectedPreset(checkForPresetMatch(dateMs, selectedEndDate));
                setSelectedStartDate(dateMs);
              }}
              selectedEndDate={selectedEndDate}
              onSelectEndDate={(dateMs) => {
                setSelectedPreset(checkForPresetMatch(selectedStartDate, dateMs));
                setSelectedEndDate(dateMs);
              }}
              showStartAndEndDates
              showPresets
              onSelectPreset={(id) => setSelectedPreset(id)}
              presets={presets}
              heading={t(`Set Timeframe`)}
              onReset={() => {
                setSelectedStartDate(defaultEscalatedTabFilters.status_changed_start_time);
                setSelectedEndDate(defaultEscalatedTabFilters.status_changed_end_time);
                setSelectedPreset("Last 5 Days");
              }}
              onClose={() => {
                const startFormatted = dayjs(selectedStartDate).utc().format("YYYY-MM-DDTHH:mm:ss") + ".000000Z";
                const endFormatted = dayjs(selectedEndDate).utc().format("YYYY-MM-DDTHH:mm:ss") + ".000000Z";
                updateQueryParams({ status_changed_start_time: startFormatted, status_changed_end_time: endFormatted });
              }}
            />
          }
        >
          <TileContainer>
            {isLoading ? (
              <GhostElement />
            ) : isError || !escalatedAlerts?.pages[0] ? (
              <div>{t(`Failed to load alerts.`)}</div>
            ) : !escalatedAlerts?.pages[0].total ? (
              <div>No alerts to show.</div>
            ) : (
              <>
                {escalatedAlerts.pages.map((page, pageIndex) => (
                  <div className="wrapper" key={pageIndex}>
                    {page.data.map((alert) => (
                      <Tile accentColor="var(--dt-ui-critical)" key={alert.id} onClick={() => navigate(`${location.pathname}/${alert.id}`)}>
                        <div>
                          <span>{alert.model_name}</span>
                          <span>#{alert.id}</span>
                        </div>
                        <div>
                          <span>Alert Escalated:</span>
                          <span>{alert.status_changed}</span>
                        </div>
                        <i className="fas fa-arrow-right"></i>
                      </Tile>
                    ))}
                  </div>
                ))}
                <div className="infinite-scroll-text" ref={lastElementRef}>
                  {hasNextPage || escalatedAlerts.pages[0].last_page === 1 ? "" : t(`No more results.`)}
                </div>
              </>
            )}
          </TileContainer>
        </Card>
      )}
    </StyledEscalations>
  );
}
